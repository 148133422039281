<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />

    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <div v-if="userInfo.service_type=='srds'">
        <a href="http://www.tech9.co.kr">
          <img src="@/assets/icons/logo3.png" style="height:56px;width:100px;object-fit:cover;" class="c-sidebar-brand-full"/>
        </a>
      </div>
      <div v-else-if="userInfo.service_type=='fumax'">
        <a href="http://www.tech9.co.kr">
          <img src="@/assets/icons/fumax_logo.png" style="height:56px;width:120px;object-fit:cover;" class="c-sidebar-brand-full"/>
        </a>
      </div>
      <div v-else-if="userInfo.service_type=='btfs'">
        <a href="http://www.tech9.co.kr">
          <img src="@/assets/icons/btfs_logo.png" style="height:56px;width:195px;object-fit:cover;" class="c-sidebar-brand-full"/>
        </a>
      </div>
      <div v-else-if="userInfo.service_type=='thcs'">
        <a href="http://www.tech9.co.kr">
          <img src="@/assets/icons/btfs_logo.png" style="height:56px;width:195px;object-fit:cover;" class="c-sidebar-brand-full"/>
        </a>
      </div>      
      <div v-else>
        <a href="http://www.tech9.co.kr">
          <img src="@/assets/icons/logo3.png" style="height:56px;width:100px;object-fit:cover;" class="c-sidebar-brand-full"/>
        </a>
      </div>
      <!-- <CIcon name="logo" height="48" alt="Logo"/> -->
    </CHeaderBrand>

    <CHeaderNav class="d-md-down-none mr-auto">

      <!-- dashboard srds -->
      <CHeaderNavItem class="px-3">
        <div v-if="userInfo.service_type=='srds'">
          <CHeaderNavLink to="/srds">
            <strong>상황판</strong>
          </CHeaderNavLink>
        </div>
      <!-- dashboard btfs -->
        <div v-else-if="userInfo.service_type=='btfs'">
          <CHeaderNavLink to="/btfs">
            <strong>상황판</strong>
          </CHeaderNavLink>          
        </div>      
      <!-- dashboard thcs -->
      <div v-else-if="userInfo.service_type=='thcs'">
          <CHeaderNavLink to="/thcs">
            <strong>상황판</strong>
          </CHeaderNavLink>          
        </div>              
      <!-- dashboard watek -->
        <div v-else-if="userInfo.service_type=='watek'">
          <CHeaderNavLink to="/watek">
            <strong>상황판</strong>
          </CHeaderNavLink>          
        </div>            
      <!-- dashboard fumax -->
        <div v-else-if="userInfo.service_type=='fumax'">
          <CHeaderNavLink to="/fumax">
            <strong>상황판</strong>
          </CHeaderNavLink>          
        </div>
        <div v-else-if="userInfo.service_type=='snow'">
          <CHeaderNavLink to="/fumax_snow">
            <strong>상황판</strong>
          </CHeaderNavLink>          
        </div>
      <!-- dashboard door -->
        <div v-else-if="userInfo.service_type=='door'">
          <CHeaderNavLink to="/door">
            <strong>상황판</strong>
          </CHeaderNavLink>          
        </div>        
      <!-- dashboard swfm -->
        <div v-else-if="userInfo.service_type=='swfm'">
          <CHeaderNavLink to="/swfm">
            <strong>상황판</strong>
          </CHeaderNavLink>          
        </div>              
      <!-- dashboard regular -->
        <div v-else>
          <CHeaderNavLink to="/dashboard_">
            <strong>상황판</strong>
          </CHeaderNavLink>          
        </div>
      </CHeaderNavItem>

      <CHeaderNavItem class="px-3">
        <!-- user regular/srds -->
        <div v-if="userInfo.service_type=='srds'">
        <CHeaderNavLink to="/srds_users" exact>
          <strong>사용자 관리</strong>
        </CHeaderNavLink>      
        </div>    
        <div v-else>
        <CHeaderNavLink to="/users">
          <strong>사용자 관리</strong>
        </CHeaderNavLink>
        </div>
      </CHeaderNavItem>

      <CHeaderNavItem class="px-3">
        <!-- sites srds  -->
        <div v-if="userInfo.service_type=='srds'">
          <CHeaderNavLink to="/srds_sites">
            <strong>데이터수집기 관리</strong>
          </CHeaderNavLink>
        </div>
        <!-- sites regular -->
        <div v-else>
          <CHeaderNavLink to="/sites">
            <strong>사이트 관리</strong>
          </CHeaderNavLink>          
        </div>
      </CHeaderNavItem>
      
      <CHeaderNavItem class="px-3">
        <!-- events srds -->
        <div v-if="userInfo.service_type=='srds'">
          <CHeaderNavLink to="/srds_events">
            <strong>상황발생 이력조회</strong>
          </CHeaderNavLink>
        </div>
        <!-- events regular -->
        <div v-else>
          <CHeaderNavLink to="/events">
            <strong>이벤트 관리</strong>
          </CHeaderNavLink>          
        </div>        
      </CHeaderNavItem>

      <CDropdown v-if="is_admin==true"
        inNav
        class="c-header-nav-items"
        placement="bottom-end"
        add-menu-classes="pt-0"
      >
        <template #toggler>
          <CHeaderNavLink>
            <strong>관리자 메뉴</strong>
          </CHeaderNavLink>
        </template>
        <CDropdownItem>
          <CHeaderNavLink to="/manage">
            <strong>장비 현황</strong>
          </CHeaderNavLink>          
        </CDropdownItem>
        <div class="dropdown-divider"></div>
        <!-- <CDropdownItem>
          <CHeaderNavLink :href="portainer" target="_blank">
            <strong>Portainer</strong>
          </CHeaderNavLink>          
        </CDropdownItem> -->
        <a role="menuitem" href="#" target="_self" class="dropdown-item">
          <a rel="noopener" :href="portainer" target="_blank" class="c-header-nav-link">
            <strong>Portainer</strong>
          </a>
        </a>
        <a role="menuitem" href="#" target="_self" class="dropdown-item">
          <a :href="explorer" target="_blank" class="c-header-nav-link">
            <strong>Explorer</strong>
          </a>
        </a>
        <a role="menuitem" href="#" target="_self" class="dropdown-item">
          <a :href="mongoexp" target="_blank" class="c-header-nav-link">
            <strong>MongoExp</strong>
          </a>
        </a>
        <a role="menuitem" href="#" target="_self" class="dropdown-item">
          <a :href="rabbitmq" target="_blank" class="c-header-nav-link">
            <strong>RabbitMQ</strong>
          </a>
        </a>
      </CDropdown>

      <!-- <CHeaderNavItem v-if="is_admin==true" class="px-3">
        <CHeaderNavLink to="/missings">
          <strong>미수신 장비</strong>
        </CHeaderNavLink>          
      </CHeaderNavItem>

      <CHeaderNavItem v-if="is_admin==true" class="px-3">
        <CHeaderNavLink :href="portainer" target="_blank">
          <strong>Portainer</strong>
        </CHeaderNavLink>          
      </CHeaderNavItem>

      <CHeaderNavItem v-if="is_admin==true" class="px-3">
        <CHeaderNavLink :href="explorer" target="_blank">
          <strong>Explorer</strong>
        </CHeaderNavLink>          
      </CHeaderNavItem>

      <CHeaderNavItem v-if="is_admin==true" class="px-3">
        <CHeaderNavLink :href="mongoexp" target="_blank">
          <strong>MongoExp</strong>
        </CHeaderNavLink>          
      </CHeaderNavItem> -->

    </CHeaderNav>

    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2">
        <div v-if="is_admin==true">
          <select class='form-control' style="height:34px" v-model='user.service_type' @click="onUserClick" @change="onChangeService()">
            <option v-for="service in service_type_info" :value="service.key" v-bind:key="service.key">{{service.value}} </option>
          </select>
        </div>
        <div v-else>
          <strong @click="onUserClick">{{userInfo.realm}} 님</strong>
        </div>
      </CHeaderNavItem>
      <CHeaderNavItem class="mx-2">
        <CHeaderNavLink>
          <img src="img/logout.png" style="height:24px;" href="" @click="onLogoutClick"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <!-- <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader> -->
  </CHeader>
</template>

<script>
import loopback from "@/services/loopback";
import utils from '@/services/utils';

export default {
  name: "TheHeader",
  components: {},
  computed: {
    portainer () {
      return loopback.portainer();
    },
    explorer () {
      return loopback.explorer(); 
    },
    mongoexp () {
      return loopback.mongoexp(); 
    },
    rabbitmq () {
      return loopback.rabbitmq(); 
    }
  },
  created: function() {
    this.user_info = this.$store.state.auth.user_info;
    this.user = this.userInfo = this.user_info.user
  },
  data() {
    var SERVICE_TYPE = utils.getServiceTypes();
    return {
      is_admin: this.$store.state.auth.is_admin,
      service_type_info: _.map(SERVICE_TYPE.USER, function(value, key) {
        return { key: key, value: value}}
      )
    };
  },
  methods: {
    onUserClick() {
      var token = loopback.getToken();
      if (token) {
        console.log(token.id);
      }
    },
    onChangeService() {
      var self = this;
      var service_type = this.user.service_type;

      this.$store.dispatch('auth/syncAuth', function(info) {
        info.user.service_type = service_type
        utils.setStorage("userInfo", info);
        self.$router.push({name: 'Dashboard_'});
      })
    }, 
    onLogoutClick() {
      this.$store.dispatch("auth/signOut").catch(this.notifyError);

      // var filter = {
      //   fields: { sensor_type: true}
      // }

      // this
      //   .$store
      //   .dispatch('rest/find', {model: 'dictionaries', filter: filter})
      //   .then((res) => {
      //     console.log('dictionaries: ', res)
      //   })

      // loopback
      //   .find('dictionaries', filter)
      //   .then((res) => {
      //     console.log('dictionaries: ', res)
      //   })
    },
  },
};
</script>
